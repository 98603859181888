<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

<!--            //Code-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="code"
                  rules="required"
                  name="The Code"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Coupon Code')"
                            name="code"
                            fou
                            v-model="formData.code">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
<!--            //Type-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="type"
                  rules="required"
                  name="The Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                    :list="builderData.typeList"
                    :listItemLabel="'name'"
                    :listItemValue="'value'"
                    v-model="formData.type">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //Value-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="value"
                  rules="required"
                  name="The Value"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Value')"
                            name="value"
                            fou
                            v-model="formData.value">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

<!--            //Use number-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="use_number"
                  rules="required"
                  name="The Use Number"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Use Number')"
                            name="use_number"
                            fou
                            v-model="formData.use_number">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

<!--            //Start date & End date-->
            <div class="row">
              <div class="col-md-6">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Start Date') }}</label>
                <fg-input v-model="formData.start_date"
                          value-format="YYYY-MM-DD HH:MM:SS"
                          type="datetime-local"
                          placeholder="Chose coupon start date"
                >
                </fg-input>
              </div>
              <div class="col-md-6">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('End Date') }}</label>
                <fg-input v-model="formData.end_date"
                          value-format="YYYY-MM-DD HH:MM:SS"
                          type="datetime-local"
                          placeholder="Chose coupon expire date"
                >

                </fg-input>
              </div>
            </div>


            <!--          Sessions-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="session_ids"
                  rules="required"
                  name="The Training Session"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="session_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Training Session')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Training Session')"
                    :list="builderData.trainingSessionList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.session_ids">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

            <!--          Cycles-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="cycle_ids"
                  rules="required"
                  name="The Training Cycles"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="cycle_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Training Cycles')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Training Cycles')"
                    :list="builderData.trainingCycleList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.cycle_ids">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>


            <!--          Member Types-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="member_type"
                  rules="required"
                  name="The Member Types"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="member_type_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Member Types')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Member Types')"
                    :list="builderData.memberTypeList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    @change="paginatedCompKey++"
                    v-model="formData.member_type_ids">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

            <!--          Members-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="member_ids"
                  rules=""
                  name="The Members"
                  v-slot="{ passed, failed,errors }">
                  <!-- <fg-select
                    name="member_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Members')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Members')"
                    :list="builderData.memberList"
                    :listItemLabel="'email'"
                    :listItemValue="'id'"
                    v-model="formData.member_ids">
                  </fg-select> -->

                  <paginated-select
                    :labelTitle="'Members'"
                    :isMultiple="true"
                    :apiUrl="'/members/paginatedMembers'"
                    :key="paginatedCompKey"
                    :filterData="{member_type_ids: formData.member_type_ids}"
                    :initData="formData.member_ids"
                    @select-changed="setMemberIds"
                  >
                  </paginated-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //IS ACTIVE-->
            <div class="form-group pt-4">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/coupons/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../components/Switch";
import PaginatedSelect from "@/components/paginatedSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    LSwitch,
    FgSelect,
    PaginatedSelect
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        code: "",
        type: "",
        value: "",
        use_number: "",
        start_date: "",
        end_date: "",
        session_ids: [],
        cycle_ids: [],
        member_ids: [],
        member_type_ids: [],
        is_active: true,
      },
      builderData: {
        trainingSessionList: [],
        trainingCycleList: [],
        memberList: [],
        memberTypeList: [],
        typeList: [],
      }
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {};
    this.axios.post("coupons/builder", data).then((response) => {
      this.builderData.trainingSessionList = response.data.trainingSessions
        .map(obj => ({...obj, title: obj.training.title + ' ( ' + obj.days + ' )'}));
      this.builderData.trainingCycleList = response.data.trainingCycles;
      // this.builderData.memberList = response.data.members;
      this.builderData.typeList = response.data.couponTypes;
      this.builderData.memberTypeList = response.data.memberTypes;

      this.builderData.trainingSessionList.unshift({id: 'all', title: 'All'})
      this.builderData.trainingCycleList.unshift({id: 'all', name: 'All'})
      this.builderData.memberTypeList.unshift({id: 'all', email: 'All'})

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Coupon");
        this.getCoupon();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Coupon");
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })


  },


  methods: {
    generateSomIdent() {
      if (!this.editMode) {
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }
        this.formData.ident = newUrl;
      }
    },
    getCoupon() {
      let data = {
        'id': this.id,
      };
      this.axios.post("coupons/get", data).then((response) => {
        this.formData = response.data;
        this.paginatedCompKey++;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Coupon Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      if (this.formData.member_ids && this.formData.member_ids.length === 0){
        this.$notify({
          message: 'You have to select members',
          timeout: 1000,
          type: 'warning'
        })
      }
      let request;
      let successMessage;
      this.submitting = true;

      this.formData.cycle_ids = this.manageFormDataArray(this.formData.cycle_ids, 'all');
      this.formData.session_ids = this.manageFormDataArray(this.formData.session_ids, 'all');
      this.formData.member_type_ids = this.manageFormDataArray(this.formData.member_type_ids, 'all');
      this.formData.member_ids = this.formData.member_ids.map((row) => {
        return row.id;
      });
      this.formData.member_ids = this.manageFormDataArray(this.formData.member_ids, 'all');

      if (this.editMode === true) {
        request = this.axios.put("coupons/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Coupon Updated Successfully");
      } else {
        request = this.axios.post("coupons/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Coupon Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/coupons/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    manageFormDataArray(array, value){
      const index = array.indexOf(value);
      if (index !== -1) {
        array.splice(0, index);
        array.splice(index + 1);
      }
      return array;
    },
    setMemberIds(memberIds) {
      this.formData.member_ids = memberIds
    },

  }
}
</script>

<style>
</style>
